import * as TabsPrimitive from '@radix-ui/react-tabs'
import * as React from 'react'
import { cn } from '#app/utils/misc'

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
	<div className="flex flex-none">
		<div className="flex flex-1">
			<TabsPrimitive.List
				ref={ref}
				className={cn(
					'flex flex-1',
					'data-[orientation=horizontal]:border-b data-[orientation=horizontal]:px-5',
					'data-[orientation=vertical]:flex-col data-[orientation=horizontal]:gap-x-8',
					className,
				)}
				{...props}
			/>
		</div>
	</div>
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Trigger
		ref={ref}
		className={cn(
			'group flex whitespace-nowrap transition-all disabled:pointer-events-none disabled:opacity-50',
			'data-[orientation=horizontal]:border-b-3 data-[orientation=horizontal]:border-transparent data-[orientation=horizontal]:data-[state=active]:border-primary data-[orientation=horizontal]:px-1 data-[orientation=horizontal]:py-3 data-[orientation=horizontal]:font-medium data-[orientation=horizontal]:data-[state=active]:text-foreground data-[orientation=horizontal]:text-text-gray data-[orientation=horizontal]:hover:border-text-gray data-[orientation=horizontal]:hover:text-foreground data-[orientation=horizontal]:focus-visible:outline-none',
			'data-[orientation=vertical]:justify-between data-[orientation=vertical]:gap-x-3 data-[orientation=vertical]:border-y data-[orientation=vertical]:data-[state=active]:border-[#C4BCFC] data-[orientation=vertical]:data-[state=inactive]:border-transparent data-[orientation=vertical]:data-[state=active]:bg-[#eeedff] data-[orientation=vertical]:p-3 data-[orientation=vertical]:hover:border-[#C4BCFC] data-[orientation=vertical]:hover:bg-[#F0EEFF]',
			className,
		)}
		{...props}
	/>
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Content
		ref={ref}
		className={cn(
			'ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
			className,
		)}
		{...props}
	/>
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsContent, TabsList, TabsTrigger }
